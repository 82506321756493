<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">收支流水</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">明细导出</el-button>
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfileTotal">统计导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <div class="a-flex-rfsc">
                    <el-select v-model="pageParam.params.searchAccountType" placeholder="请选择" style="width: 94px">
                        <el-option
                            v-for="(item,index) in accountTypeDic"
                            :key="index"
                            :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <le-input class="companySelect" v-show="pageParam.params.searchAccountType == 0" label="" v-model="accountPersonal" />
                    <le-company-under-select placeholder="请选择（可输入搜索）" v-show="pageParam.params.searchAccountType == 1" class="companySelect" label="" :multiple="true" :collapse="true" v-model="accountCompany"></le-company-under-select>
                </div>
                <le-input label="三方交易号" v-model="pageParam.params.transactionId" />
                <le-input label="订单编号" v-model="pageParam.params.orderId" />
                <le-date-range label="日期" ref="dateRange" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" :clearable='false' />
                <le-company-under-select placeholder="请选择（可输入搜索）" label="收款商户" :readonly='userInfo.dataPermission == 77?true:false' v-model="pageParam.params.payeeCompany"></le-company-under-select>
            </le-search-form>
            <le-pagview ref="financeSZLS" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeSZLSList">
                <el-table ref="financeSZLSList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" @filter-change='filterFun' style="width: 100%">
                    <el-table-column prop="transactionId" label="三方交易号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.transactionId?row.transactionId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderId" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="toOrderDetails(row)">{{ row.orderId && row.orderId!='null'?row.orderId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="账户" min-width="160">
                        <template slot-scope="{ row }">
                            <div v-if="row.accountType == 0">
                                <span v-if="userInfo.dataPermission == 77">{{ util.hidePhoneNumber(row.account) }}</span>
                                <span v-else>{{ row.account?row.account:'-' }}</span>
                            </div>
                            <span v-if="row.accountType == 1">{{ row.account?row.account:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payeeCompanyName" label="收款商户" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.payeeCompanyName?row.payeeCompanyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="支付渠道" 
                        min-width="103"
                        column-key="payChannel"
                        :filter-multiple='false'
                        :filters="payChannelDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChanel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="账户类型" 
                        min-width="103"
                        column-key="accountType"
                        :filter-multiple='false'
                        :filters="accountTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.accountType | initDic(accountTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="事件" 
                        min-width="103"
                        column-key="event"
                        :filter-multiple='false'
                        :filters="eventDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.event | initDic(eventDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="商品类型" 
                        min-width="103"
                        column-key="productType"
                        :filter-multiple='false'
                        :filters="goodsTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.productType | initDic(goodsTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="类型" 
                        min-width="103"
                        column-key="recordType"
                        :filter-multiple='false'
                        :filters="typeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordType | initDic(typeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myOrderAmount" label="金额" min-width="103">
                        <template slot-scope="{ row }">
                            <span v-if="row.recordType==1">-</span>
                            <span>￥{{ util.numFormat(row.amount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" min-width="103">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-cfsfs">
                                <span class="a-fs-12">退款历史</span>
                                <span class="a-fs-12">已入账金额</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.adjustedRevenue) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="创建时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-'  }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <div class="a-flex-rfsc" style="margin-right: 40px">
                            <span class="a-fs-12 a-c-normal">收入：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.income)}}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">支出：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.expend)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from 'vuex';
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.turnoverList,
                    method: "post",
                    params: {
                        account: [],//账户
                        accountType: '',//账户类型
                        searchAccountType: '',
                        event: '',//事件
                        orderId: '',//订单编号
                        payChanel: '',//支付渠道
                        productType: '',//商品类型
                        recordType: '',//记录类型
                        transactionId: '',//三方交易号
                        startDate: '',
                        endDate: '',
                        payeeCompany: ''
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                payChannelDic: [],//支付渠道
                accountTypeDic: [],//账户类型
                eventDic: [],//事件
                goodsTypeDic: [],//商品类型
                typeDic: [],//类型
                accountPersonal: '',
                accountCompany: [],
            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.user,
                company: state => state.company.company,
            })
        },
        created () {
            // this.pageParam.params.startDate = this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
            // this.pageParam.params.endDate = this.$getDay.getTodayBeforeDays(1) + ' 00:00:00'
            if(this.userInfo.dataPermission == 77) {
                this.pageParam.params.payeeCompany = 2
            }
            this.pageParam.params.startDate = this.$getDay.getToday() + ' 00:00:00'
            this.pageParam.params.endDate = this.$getDay.getToday() + ' 00:00:00'
            this.$getDic('accountType').then(res=>{ 
                this.accountTypeDic = res;
                this.pageParam.params.searchAccountType = res[0].value;
            })
            this.$getDic('eventType').then(res=>{ this.eventDic = res; })
            this.$getDic('productType').then(res=>{ this.goodsTypeDic = res; })
            this.$getDic('pecodeType').then(res=>{ this.typeDic = res; })
            this.$getDic('turnoverPayChannel').then(res=>{ this.payChannelDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data,fileUrl) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    account: [],//账户
                    accountType: '',//账户类型
                    searchAccountType: this.accountTypeDic[0].value,
                    event: '',//事件
                    orderId: '',//订单编号
                    payChanel: '',//支付渠道
                    productType: '',//商品类型
                    recordType: '',//记录类型
                    transactionId: '',//三方交易号
                    startDate: this.$getDay.getToday() + ' 00:00:00',
                    endDate: this.$getDay.getToday() + ' 00:00:00',
                    payeeCompany: ''
                };
                this.accountPersonal = ''
                this.accountCompany = []
                this.$refs['financeSZLSList'].clearFilter()
                this.$refs['dateRange'].value1 = [this.$getDay.getToday() + ' 00:00:00',this.$getDay.getToday() + ' 00:00:00']
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                if(this.pageParam.params.searchAccountType == 1) {
                    this.pageParam.params.account = this.accountCompany
                }else{
                    this.pageParam.params.account = this.accountPersonal?[this.accountPersonal]:[]
                }
                this.$refs['financeSZLS'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='payChannel'){
                        if(value[key].length==0){
                            this.pageParam.params.payChanel = ''
                        }else{
                            this.pageParam.params.payChanel = value[key][0]
                        }
                    }
                    if(key=='event'){
                        if(value[key].length==0){
                            this.pageParam.params.event = ''
                        }else{
                            this.pageParam.params.event = value[key][0]
                        }
                    }
                    if(key=='productType'){
                        if(value[key].length==0){
                            this.pageParam.params.productType = ''
                        }else{
                            this.pageParam.params.productType = value[key][0]
                        }
                    }
                    if(key=='recordType'){
                        if(value[key].length==0){
                            this.pageParam.params.recordType = ''
                        }else{
                            this.pageParam.params.recordType = value[key][0]
                        }
                    }
                    if(key=='accountType'){
                        if(value[key].length==0){
                            this.pageParam.params.accountType = ''
                        }else{
                            this.pageParam.params.accountType = value[key][0]
                        }
                    }
                }
                this.$refs['financeSZLS'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 跳转订单详情（区分类型）
            toOrderDetails (datas) {
                switch (datas.productType) {
                    case 0:
                        if (!datas.orderId || datas.orderId=='null' || !this.$_has(4)) return
                        this.$router.push({
                            path: '/order/order-detail',
                            query: {
                                'orderId': datas.orderId
                            }
                        })
                        break;
                    case 1:
                        if (!datas.orderId || datas.orderId=='null' || !this.$_has(72)) return
                        this.$router.push({
                            path:'/order/package-order-info',
                            query: {
                                id: datas?datas.orderId:''
                            }
                        })
                        break;
                    case 4:
                        if (!datas.orderId || datas.orderId=='null' || !this.$_has(86)) return
                        window.sessionStorage.setItem('fromPath','finance-szls-list')
                        this.$router.push({
                            path:'/order/water-order-list',
                            query: {
                                id: datas?datas.orderId:''
                            }
                        })
                        break;
                    case 5:
                    case 6:
                        if (!datas.orderId || datas.orderId=='null' || !this.$_has(101)) return
                        this.$router.push({
                            path:'/order/chargeCabinet-order-info',
                            query: {
                                orderId: datas.orderId,
                                orderSn: datas.orderId
                            }
                        })
                        break;
                    default:
                        break;
                }
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 3,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 收支流水明细',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            },
            exportfileTotal () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 5,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 收支流水统计',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
</style>